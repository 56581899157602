import * as React from "react";
import Layout from "../components/layout";
import Section from "../components/layout/section";
import "./card.css";
import Heading from "../components/text/myheading";
import Lead from "../components/text/lead";
import styled from "@emotion/styled";
import facepaint from "facepaint";
import { StaticImage } from "gatsby-plugin-image";

const breakpoints = [576, 768, 992, 1200, 1300];

const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const Container = styled.div((props) => {
  return mq({
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: ['column-reverse', 'column-reverse', 'row'],
  });
});

const sectionStyle = {
  display: "flex",
  maxWidth: "40rem",
  margin: "0 auto",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "0.5rem 0.5rem 0 0",
  overflow: "hidden",
};

const headingMedium = {
  fontWeight: "300",
  color: "#3f51b5",
  fontFamily: "Quicksand,Helvetica,Arial,sans-serif",
  marginBottom: "1.2rem",
  marginTop: "1.2rem",
  fontSize: "1.75rem",
  textAlign: "center",
};

const headingMediumWhite = {
  fontWeight: "300",
  color: "white",
  fontFamily: "Quicksand,Helvetica,Arial,sans-serif",
  marginBottom: "1.2rem",
  marginTop: "1.2rem",
  fontSize: "1.75rem",
  textAlign: "center",
};

const paragraph = {
  fontFamily: "Roboto,sans-serif",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "32px",
  margin: "1em 0 2em",
  maxWidth: "800px",
  textAlign: "center",
};

const paragraphInverted = {
  fontFamily: "Roboto,sans-serif",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "32px",
  margin: "1em 0 2em",
  maxWidth: "800px",
  textAlign: "center",
  color: "white",
};

const ColumnFirst = styled.div((props) => {
  return mq({
    width: ["100%", "100%", "50%"],
    margin: ["0px auto", "0px auto", "50px auto"],
    padding: "0 2em",
    maxWidth: '500px',
  });
});

const ColumnSecond = styled.div((props) => {
  return mq({
    width: ["100%", "100%", "50%"],
  });
});


const About = () => {
  return (
    <Layout>
      <Section color="bright" padding="0">
        <Container>
          <ColumnFirst>
            <Heading element="h1" padding={'1em'} color={'white'} align="left">
              About Us
            </Heading>
            <Lead element="p" color="white" align="left">
              We are a small, family-owned business that has been working in
              Southwest Florida since 2007.
            </Lead>
          </ColumnFirst>
          <ColumnSecond>
          <StaticImage
            src="../images/about/kitchen.jpg"
            alt="vinyl plank icon"
            placeholder="blurred"
            style={{ height: "100%", width: "100%" }}
          />
          </ColumnSecond>
        </Container>
      </Section>
      <Section>
        <section style={sectionStyle}>
          <h2 style={headingMedium}>Established in 2007</h2>
          <p style={paragraph}>
            We began as a home improvement service. Although we enjoyed working
            on different home repair and improvement projects, we particularly
            enjoyed installing flooring. In 2014 we launched Affordable Flooring
            and began to focus our business on flooring installation only.
          </p>
        </section>
      </Section>
      <Section color="bright">
        <section style={sectionStyle}>
          <h2 style={headingMediumWhite}>Our Goal</h2>
          <p style={paragraphInverted}>
            Our goal is to provide our customers with beautiful floors that are
            meticulously installed at an affordable price.
          </p>
        </section>
      </Section>
      <Section>
        <section style={sectionStyle}>
          <h2 style={headingMedium}>We're Here for You</h2>
          <p style={paragraph}>
            Unlike a large flooring company with many different employees, we
            are a small company. As the owner/operator, I am involved in every
            aspect of the project, from estimates to installation. I am
            available during business hours and can be contacted via phone,
            email, or text message.
          </p>
        </section>
      </Section>
    </Layout>
  );
};

export default About;
