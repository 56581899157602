import * as React from "react";
import styled from "@emotion/styled";
import facepaint from "facepaint";

const breakpoints = [576, 768, 992, 1200];

const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const Section = ({ children, color, backgroundColor, padding, height }) => {
  var background = (color === 'bright') ? "#3f51b5" : "white"
  const SectionWithStyle = styled.div((props) => {
    return mq({
    paddingBottom: "2em",
    paddingTop: "2em",
    padding: padding ? padding : "2em",
    backgroundColor: backgroundColor || background,
    fontSize: "16px",
    lineHeight: "1.5",
    textAlign: "center",
    height: height ? height : 'auto',
    overflow: 'hidden',
    color: color
  })
})

  return <SectionWithStyle as="section">{children}</SectionWithStyle>;
};

export default Section;
