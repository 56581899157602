import * as React from "react";
import {
   createStyles,
   Text,
 } from '@mantine/core';


const useStyles = createStyles((theme) => ({
   lead: {
      fontFamily: "Open Sans,Helvetica Neue,Arial,Helvetica,Verdana,sans-serif",
      fontSize: "24px",
      fontWeight: 300,
      letterSpacing: "-.02em",
      lineHeight: "40px",
      paddingBottom: "2em",
      paddingTop: "0px",
      maxWidth: "800px",
      margin: "auto",
     [theme.fn.smallerThan('sm')]: {
       fontSize: 18,
       paddingTop: 0
     },
   },
 }));

export const Lead = ({element, children, color, align}) => {

  const { classes } = useStyles();
  return (
   <Text component={element} className={classes.lead} color={color} align={align}>
      {children}
   </Text>
  );
};

export default Lead;