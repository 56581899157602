import * as React from "react";
import styled from "@emotion/styled";
import facepaint from "facepaint";

const breakpoints = [576, 768, 992, 1200];

const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const StyledHeading = styled.h1(({ color, align, style, padding }) => {
  return mq({
    fontFamily: "Open Sans,Helvetica Neue,Arial,Helvetica,Verdana,sans-serif",
    fontSize: [28, 32, 40],
    paddingTop: padding ? padding : "0em",
    fontWeight: "500",
    color: color ? color : '#3f51b5',
    textAlign: align ? align : 'left',
    ...style
  });
});

export const Heading = ({ element, children, padding, color, align, style }) => {
  return (
    <StyledHeading as={element} style={style} color={color} align={align} padding={padding}>
      {children}
    </StyledHeading>
  );
};

export default Heading;
